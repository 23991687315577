import React from 'react';
import styled from '@emotion/styled';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';

const PostContent = styled.div`
    margin-top: 0;
    a {
        color: ${props => props.theme.colors.link};
        &:hover {
            color: ${props => props.theme.colors.linkHover};
        }
    }
`;

const PageIndex = ({ data, pageContext }) => {
    const posts = data.allMarkdownRemark.edges;
    const { slug, info } = pageContext;

    const slugs = slug.split("/").filter(el => el.trim() !== '');

    return (
    <Layout title={slugs[0]} sideContent={{ title: slugs[0], content: info}}>
        {
            posts.map(({ node }) => {
                const { title, date, releasedOn, indexSnippet, cover } = node.frontmatter;
                const { slug } = node.fields;
                const key = node.id;

                return(
                    <Container key={key} title={title} slug={slug} cover={cover}>
                        <PostContent dangerouslySetInnerHTML={{ __html: indexSnippet }} />
                    </Container>
                );
            })
        }
    </Layout>
  )
}

export default PageIndex;

PageIndex.propTypes = {
    pageContext: PropTypes.shape({
      slug: PropTypes.string,
      slugRegex: PropTypes.string,
      info: PropTypes.string,
    }).isRequired,
    data: PropTypes.object.isRequired,
  };

  export const query = graphql`
      query PageBySlugPart($slugRegex: String!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC},
            filter: { frontmatter: { category: { ne: "index" } }, fields: {slug: {regex: $slugRegex}}}
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
                        releasedOn
                        indexSnippet
                        cover {
                            childImageSharp {
                                fluid(maxWidth: 3720) {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  `;
